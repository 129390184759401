<template>
  <div class="uk-container uk-container-expand uk-margin-top card-scrollable">
    <div uk-grid>
      <div class="uk-width-2-3@s font-header">
        Tambah Kegiatan
      </div>
      <div class="uk-width-1-3@s">
        <bread-crumb :bread-crumb="breadcrumb" />
      </div>
    </div>

    <container />
    <div id="container-modal-add-cage-activity" />
    <modal-out-confirm />
    <modal-save-confirm />
    <modal-contract-price />
    <modal-show-alert-create-activity />
  </div>
</template>

<script>
import Container from './Container'
import ModalOutConfirm from './ModalOutConfirm'
import ModalSaveConfirm from './ModalSaveConfirm'
import ModalContractPrice from './ModalContractPrice'
import BreadCrumb from '@/components/globals/breadcrumb'
import ModalShowAlertCreateActivity from './ModalShowAlertCreateActivity'

export default {
  components: {
    Container,
    ModalOutConfirm,
    ModalSaveConfirm,
    ModalContractPrice,
    ModalShowAlertCreateActivity,
    BreadCrumb
  },
  data() {
    return {
      breadcrumb: [
        {
          link: '/admin/kegiatan-kandang',
          title: 'Kegiatan Kandang'
        },
        {
          link: null,
          title: 'Tambah Kegiatan'
        }
      ]
    }
  }
}
</script>

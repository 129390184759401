<template>
  <div>
    <div class="uk-margin-top">
      <div class="uk-card uk-card-default kandang-card">
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Kode Siklus
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.cycle_code"
                  v-validate="{ required: true, regex: '^[A-Za-z0-9 _]*[A-Za-z0-9][A-Za-z0-9 _]*$' }"
                  class="uk-input"
                  type="text"
                  name="kode_siklus"
                  placeholder="Masukan kode..."
                  :class="{'uk-form-danger': errors.has('kode_siklus')}"
                >
                <div
                  v-show="errors.has('kode_siklus')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('kode_siklus') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Nama Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-form-controls">
                  <multiselect 
                    id="farm" 
                    v-model="cageChoose" 
                    v-validate="'required'"
                    label="name" 
                    name="nama_kandang" 
                    track-by="cage_code" 
                    placeholder="Pilih..." 
                    open-direction="bottom" 
                    :options="dataCages" 
                    :searchable="true"
                    :loading="isLoading" 
                    :close-on-select="true" 
                    :max-height="500"
                    :show-no-results="true"
                    @select="onSelectCage"
                    @remove="onRemoveCage"
                  >
                    <span slot="noResult">Oops! Data tidak ditemukan.</span>
                  </multiselect>
                  <div
                    v-show="errors.has('nama_kandang')"
                    class="uk-text-small uk-text-danger"
                  >
                    {{ errors.first('nama_kandang') }}
                  </div>
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Anak Kandang
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.cage_staff_name"
                  class="uk-input"
                  type="text"
                  name="cage_child"
                  disabled
                >
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Pengawas Lapangan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.field_supervisor_name"
                  class="uk-input"
                  type="text"
                  name="field_supervisor"
                  disabled
                >
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Harga Kontrak
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <multiselect 
                  id="chick_supplier" 
                  v-model="priceTypeChoose" 
                  label="name"
                  name="price_type" 
                  track-by="name" 
                  placeholder="Pilih..." 
                  open-direction="bottom" 
                  :options="contractPriceTypeList" 
                  :searchable="true" 
                  :loading="isLoading"
                  :close-on-select="true" 
                  :max-height="500"
                  :show-no-results="true"
                  @select="onSelectDocs"
                >
                  <span slot="noResult">Oops! Data tidak ditemukan.</span>
                </multiselect>
                <div
                  v-show="errors.has('merek_doc')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('merek_doc') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Populasi (ekor)
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <input
                  v-model="formData.population"
                  v-validate="'required|numeric'"
                  class="uk-input"
                  type="text"
                  name="populasi"
                  placeholder="Masukan populasi..."
                  :class="{'uk-form-danger': errors.has('populasi')}"
                >
                <div
                  v-show="errors.has('populasi')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('populasi') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Kode Harga
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <div class="uk-width-1 price-field">
                  <input
                    v-model="formData.contract_price_code"
                    class="uk-input"
                    type="text"
                    name="contract_price"
                    disabled
                  >
                  <label
                    :class="[formData.contract_price_code ? 'label-price': 'label-price-muted']"
                    @click="detailPrice"
                  >Lihat</label>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="bottom-side" />
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startPrepareDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_persiapan"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('mulai_persiapan') && !startPrepareDate}"
                />
                <div
                  v-show="errors.has('mulai_persiapan') && !startPrepareDate"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_persiapan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Persiapan
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="endPrepareDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_persiapan"
                  :disabled-date="disabledEndPrepareDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('mulai_persiapan')}"
                />
                <div
                  v-show="errors.has('mulai_persiapan')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_persiapan') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick In
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="chickInView"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_in"
                  :disabled-date="disabledChickInDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('chick_in')}"
                />
                <div
                  v-show="errors.has('chick_in')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('chick_in') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Hari ke-1
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startMaintenance"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_in"
                  :disabled-date="disabledDates"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('chick_in')}"
                />
                <div
                  v-show="errors.has('chick_in')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('chick_in') }}
                </div>
              </div>
            </div>
          </div>
          <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Panen
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startHarvestDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="mulai_panen"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('mulai_panen')}"
                  disabled
                />
                <div
                  v-show="errors.has('mulai_panen')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('mulai_panen') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Chick Out
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="startChickOutDate"
                  v-validate="'required'"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :format="formatDate"
                  :class="{'uk-form-danger': errors.has('chick_out')}"
                  disabled
                />
                <div
                  v-show="errors.has('chick_out')"
                  class="uk-text-small uk-text-danger"
                >
                  {{ errors.first('chick_out') }}
                </div>
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Mulai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="sanitationView"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :format="formatDate"
                  disabled
                />
              </div>
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                Selesai Sanitasi
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                <datepicker
                  v-model="endSanitationView"
                  input-class="uk-input"
                  placeholder="DD-MM-YYYY"
                  name="chick_out"
                  :format="formatDate"
                  disabled
                />
              </div>
            </div>
          </div>
        </div>
        <div
          class="uk-child-width-expand@s uk-grid-small"
          uk-grid
        >
          <div class="uk-width-1-1 uk-text-right">
            <button
              class="uk-button uk-button-danger uk-width-expand uk-width-auto@s"
              type="button"
              @click="goToBack"
            >
              Batal
            </button>
            <button
              class="uk-button uk-button-primary uk-margin-top uk-margin-remove-top-med uk-margin-remove-left@s uk-margin-left-med uk-width-expand uk-width-auto@s"
              type="button"
              @click="showSaveConfirmModal"
            >
              <img
                v-lazy="`${images}/icon/save.svg`"
                alt=""
                class="uk-margin-small-right"
              >
              Simpan
            </button>
          </div>
        </div>
      </div>
    </div>
    <div
      v-show="showAddActivities"
      class="uk-margin-top uk-margin-bottom"
    >
      <SidebarActivity
        :panen-date="panenDate"
        :chick-out-date="chickOutDate"
        @dataPemeliharaanLength="onGetLength"
        @dataDetailLogTemp="onGetDetailLogTemp"
        @dataDetailLog="onGetDetailLog"
      />
    </div>
  </div>
</template>

<script>
import { mapMutations, mapActions, mapGetters } from 'vuex'
import { PREFIX_IMAGE, DURATION_ACTIVITY, CONTRACT_PRICE_TYPE_LIST } from '@/utils/constant'
import { dateString, dateAddDays, dateSubstract } from '@/utils/formater'
import SidebarActivity from './sidebar'
import Datepicker from 'vue2-datepicker'
import 'vue2-datepicker/index.css'
import 'vue2-datepicker/locale/id'

export default {
  components: {
    SidebarActivity,
    Datepicker
  },
  data() {
    return {
      sanitateChanged: false,
      showProduction: process.env.VUE_APP_ENV == 'production' || process.env.VUE_APP_ENV == 'uat',
      isLoading: false,
      images: PREFIX_IMAGE,
      formatDate: 'DD MMMM YYYY',
      startPrepareDate: '',
      endPrepareDate: '',
      startHarvestDate: '',
      startChickOutDate: '',
      panenDate: 0,
      chickOutDate: 0,
      cageCategoryDate: null,
      maintenanceLength: null,
      formData: {
        cycle_code: '',
        population: '',
        cage_id: '',
        cage_staff: '',
        field_supervisor: '',
        start_preparation: '',
        chick_in: '',
        chick_out: '',
        harvest: '',
        sanitation: '',
        cage_staff_name: '',
        field_supervisor_name: '',
        contract_price_id: '',
        contract_price_code: '',
        chick_supplier_id: '',
        log_activity: []
      },
      contractPriceByChickin: '',
      cycles: [],
      activities: [],
      showAddActivities: false,
      cageChoose: '',
      dataCages: [],
      formActivities: [],
      tempActivities: [],
      getToday: new Date(),
      chickInView: '',
      startMaintenance: '',
      sanitationView: '',
      endSanitationView: '',
      priceTypeChoose: '',
      contractPriceTypeList: []
    }
  },
  computed: {
    ...mapGetters({
      cages: 'cage/cages',
      merkDocs: 'merkDoc/merkDocs',
      contractPrice: 'contractPrice/chickinContractPrice',
      showAlert: 'activityCage/showAlert'

    })
  },
  watch: {
    async priceTypeChoose(){
      if(this.formData.chick_in){
        await this.getContractPrice(
          {
            effective_date: dateString(this.formData.chick_in),
            price_type: Number(this.priceTypeChoose.value)
          }
        )
        if(this.contractPrice){
          this.contractPriceByChickin = this.contractPrice
          this.formData.contract_price_id = this.contractPriceByChickin.id
          this.formData.contract_price_code = this.contractPriceByChickin.code
        }
      }
    },
    startPrepareDate() {
      this.formData.start_preparation = dateAddDays(this.startPrepareDate, 0)
      const toDay = DURATION_ACTIVITY.START_PREPARATION // PLUS 6 DAY
      const addDays = dateAddDays(this.startPrepareDate, toDay)
      this.formData.end_preparation = addDays
      this.endPrepareDate = addDays ? new Date(addDays) : ''
    },
    endPrepareDate() {
      this.formData.end_preparation = dateAddDays(this.endPrepareDate, 0)
      const toDay = DURATION_ACTIVITY.END_PREPARATION // PLUS 1 DAY
      const addDays = dateAddDays(this.endPrepareDate, toDay)
      this.formData.chick_in = addDays
      this.chickInView = addDays ? new Date(addDays) : ''
    },
    async chickInView() {
      this.formData.chick_in = dateAddDays(this.chickInView, 0)
      const toDay = DURATION_ACTIVITY.CHICK_IN // PLUS 1 DAY
      const addDays = dateAddDays(this.chickInView, toDay)
      const toDayHarvest = DURATION_ACTIVITY.CHICK_IN_HARVEST // PLUS 36 DAY
      const addDaysHarvest = dateAddDays(this.chickInView, toDayHarvest)
      this.formData.start_maintenance = addDays
      this.formData.harvest = addDaysHarvest
      this.startMaintenance = addDays ? new Date(addDays) : ''
      this.startHarvestDate = addDaysHarvest ? new Date(addDaysHarvest) : ''
      //for contract price
      if(this.formData.chick_in){
        if(this.priceTypeChoose){
          await this.getContractPrice(
            {
              effective_date: dateString(this.formData.chick_in),
              price_type: Number(this.priceTypeChoose.value)
            }
          )
        }
      }
      if(this.contractPrice){
        this.contractPriceByChickin = this.contractPrice
        this.formData.contract_price_id = this.contractPriceByChickin.id
        this.formData.contract_price_code = this.contractPriceByChickin.code
      }
    },
    startMaintenance() {
      this.formData.start_maintenance = dateAddDays(this.startMaintenance, 0)
      const diffHarvestDate = (new Date(this.startHarvestDate).getTime() - new Date(this.startMaintenance).getTime())
      this.panenDate = diffHarvestDate/(1000 * 60 * 60 * 24)
      const tommorowDay = DURATION_ACTIVITY.CHICK_IN_HARVEST
      const addDays = dateAddDays(this.startMaintenance, tommorowDay)
      const startMaintenanceEnd = addDays
      this.formData.harvest = startMaintenanceEnd
      this.startHarvestDate = addDays ? new Date(startMaintenanceEnd) : ''
    },
    startHarvestDate() {
      this.formData.harvest = dateAddDays(this.startHarvestDate, 0)
      const diffHarvestDate = (new Date(this.startHarvestDate).getTime() - new Date(this.startMaintenance).getTime())
      this.panenDate = diffHarvestDate/(1000 * 60 * 60 * 24)
      const diffChickOutDate = (this.startChickOutDate - this.startHarvestDate)
      this.chickOutDate = (diffChickOutDate/(1000 * 60 * 60 * 24)) + 1
      const toDay = DURATION_ACTIVITY.HARVEST // PLUS 2 DAY
      const addDays = dateAddDays(this.startHarvestDate, toDay)
      this.formData.chick_out = addDays
      this.startChickOutDate = addDays ? new Date(addDays) : ''
    },
    startChickOutDate() {
      this.formData.chick_out = dateAddDays(this.startChickOutDate, 0)
      const diffChickOutDate = (this.startChickOutDate - this.startHarvestDate)
      this.chickOutDate = (diffChickOutDate/(1000 * 60 * 60 * 24)) + 1
      const toDay = DURATION_ACTIVITY.CHICK_OUT // PLUS 1 DAY
      const addDays = dateAddDays(this.startChickOutDate, toDay)
      this.formData.sanitation = addDays
      this.sanitationView = addDays ? new Date(addDays) : ''
    },
    sanitationView() {
      this.formData.sanitation = dateAddDays(this.sanitationView, 0)
      const toDay = DURATION_ACTIVITY.SANITATION /* PLUS 7 DAY */
      const addDays = dateAddDays(this.sanitationView, toDay)
      this.formData.end_sanitation = addDays
      this.sanitateChanged = true
      this.endSanitationView =  this.sanitateChanged ? new Date(addDays) : ''
    },
    endSanitationView() {
      this.sanitateChanged = false
      this.formData.end_sanitation = dateAddDays(this.endSanitationView, 0)
    }
  },
  async mounted() {
    await Promise.all([
      this.getCage({is_all: true}),
      this.getMerkDoc({['by_item_category']: 'doc',is_all: true})
    ])

    this.contractPriceTypeList = CONTRACT_PRICE_TYPE_LIST.filter(item => item.name !== 'Semua').map(item => {
      if (item.name === 'Rp.0') {
        return { name: 'Normal', value: item.value }
      }
      return item
    })

    this.dataCages = this.cages ? this.cages : []
  },
  methods: {
    onGetLength (length) {
      this.maintenanceLength = length
    },
    onGetDetailLogTemp (temp) {
      this.tempActivities = temp
    },
    onGetDetailLog (detail) {
      this.formActivities = detail
    },
    distinctLogDetail() {
      // PARENT DATA ASSIGN
      this.formData.start_preparation = dateString(this.startPrepareDate)
      this.formData.end_preparation = dateString(this.endPrepareDate)
      this.formData.chick_in = dateString(this.chickInView)
      this.formData.start_maintenance = dateString(this.startMaintenance)
      this.formData.harvest = dateString(this.startHarvestDate)
      this.formData.chick_out = dateString(this.startChickOutDate)
      this.formData.sanitation = dateString(this.formData.sanitation)
      this.formData.end_sanitation = dateString(this.formData.end_sanitation)

      this.formActivities.forEach(el => {
        const result = this.tempActivities.find(x => x.name === el.flag)
        if (result) {
          let dateAssign = ''
          let dateAssignEnd = ''
          let asignChild = []

          if (result.name && result.name === 'PERSIAPAN KANDANG') {
            dateAssign = this.formData.start_preparation
            dateAssignEnd = this.formData.end_preparation

            result.work_date_start = this.formData.start_preparation
            result.work_date_end = this.formData.end_preparation
          } else if (result.name && result.name === 'CHICK IN') {
            dateAssign = this.formData.chick_in

            result.work_date_start = this.formData.chick_in
            result.work_date_end = this.formData.chick_in
          } else if (result.name && result.name === 'SANITASI') {
            dateAssign = this.formData.sanitation

            result.work_date_start = this.formData.sanitation
            result.work_date_end = this.formData.end_sanitation
          } else if (result.name && result.name.includes('PANEN HARI KE')) {
            dateAssign = this.getHarvestDateDayActivity(result.name)
            result.work_date_start = dateAssign
            result.work_date_end = dateAssign
          } else if (result.name && result.name.includes('HARI KE')) {
            // DIFF DAYS FOR ASSIGN DATE
            // const diffDaysDateHarvest = dateDiffDays(this.formData.harvest, this.formData.chick_in)
            // const diffDaysDateChickOut = dateDiffDays(this.formData.chick_out, this.formData.chick_in)

            // if (result.name === `HARI KE ${diffDaysDateHarvest}`) {
            //   dateAssign = this.formData.harvest
            //   result.work_date_start = this.formData.harvest
            //   result.work_date_end = dateString(dateSubstract(this.formData.chick_out, 1, 'day'))
            // } else if (result.name === `HARI KE ${diffDaysDateChickOut}`) {
            //   dateAssign = this.formData.chick_out
            //   result.work_date_start = this.formData.chick_out
            //   result.work_date_end = this.formData.chick_out
            // } else {
            //   dateAssign = this.getDateDayActivity(result.name)
            //   result.work_date_start = dateAssign
            //   result.work_date_end = dateAssign
            // }
            dateAssign = this.getDateDayActivity(result.name)
            result.work_date_start = dateAssign
            result.work_date_end = dateAssign
          } else { // if custom tahapan
            this.setShowAlert({ is_show: true, name: (result.name || '-'), type: 0 })
          }

          if (result.name && result.name === 'PERSIAPAN KANDANG') {
            const dataAsignChild = {
              id: el.id,
              name: el.name,
              activity_category_id: el.activity_category_id,
              sub_activity_category_id: el.sub_activity_category_id,
              activity_id: result ? result.id : '',
              input_type_id: el.input_type_id,
              description: el.description,
              time_start: `${dateAssign} ${el.time_start}`,
              time_end: `${dateAssignEnd} ${el.time_end}`
            }
            asignChild = dataAsignChild
          } else {
            const dataAsignChild = {
              id: el.id,
              name: el.name,
              activity_category_id: el.activity_category_id,
              sub_activity_category_id: el.sub_activity_category_id,
              activity_id: result ? result.id : '',
              input_type_id: el.input_type_id,
              description: el.description,
              time_start: `${dateAssign} ${el.time_start}`,
              time_end: `${dateAssign} ${el.time_end}`
            }
            asignChild = dataAsignChild
          }
          const indexDetail = result.log_detail_activity.findIndex(x => x.id === el.id)
          const notFound = -1
          if (el.id && indexDetail !== notFound) { // prevent duplicate
            result.log_detail_activity.splice(indexDetail, 1, asignChild)
          } else {
            result.log_detail_activity.push(asignChild)
          }
        } /* else { // if activity data on master log detail activity not found in activity => if custom activity detail
          this.setShowAlert({ is_show: true, name: (el.name || '-'), type: 1 })
        } */
      })
      // PARENT DATA ASSIGN DETAIL
      this.formData.log_activity = this.tempActivities
    },
    getDateDayActivity(dayName) {
      const splitDay = dayName.split('HARI KE ')
      const addDays = parseInt(splitDay[1])
      const dateDayActive = dateSubstract(this.startMaintenance, 1, 'day')
      const dayResult = dateAddDays(dateDayActive, addDays)
      return dateString(dayResult)
    },
    getHarvestDateDayActivity(dayName) {
      const splitDay = dayName.split('PANEN HARI KE ')
      const addDays = parseInt(splitDay[1])
      const dateDayActive = dateSubstract(this.startHarvestDate, 1, 'day')
      const dayResult = dateAddDays(dateDayActive, addDays)
      return dateString(dayResult)
    },
    ...mapActions({
      getCage: 'cage/getCage',
      getContractPrice: 'contractPrice/getContractPriceByChickin',
      getMerkDoc: 'merkDoc/getMerkDoc',
      getActivity: 'activity/getActivity',
      getMasterActivityDetail: 'masterActivityDetail/getMasterActivityDetail'
    }),
    ...mapMutations({
      setModalAdd: 'activityCage/SET_MODAL_ADD',
      setShowAlert: 'activityCage/SET_SHOW_ALERT',
      setModalPrice: 'contractPrice/SET_MODAL_PRICE'
    }),
    clearCodeCage () {
      this.showAddActivities = false
      this.formData.code_cage = null
      this.formData.cage_name = ''
      this.formData.cage_child = ''
      this.formData.field_supervisor = ''
      this.formData.start_prepare = ''
      this.formData.chick_in = ''
      this.formData.start_harvest = ''
      this.formData.chick_out = ''
      this.formData.sanitasi = ''
    },
    clearActivity () {
      this.formActivity.code_cage = null
      this.formActivity.cage_name = ''
      this.formActivity.cage_child = ''
      this.formActivity.field_supervisor = ''
      this.formActivity.start_prepare = ''
      this.formActivity.chick_in = ''
    },
    async onSelectCage(val) {
      this.cageCategoryDate = val.chick_capacity
      this.sanitationView = new Date(this.formData.sanitation)
      this.showAddActivities = true
      this.formData.cage_id = val.id
      this.formData.cage_staff_name = val.cage_staff
      this.formData.cage_staff = val.cage_staff_id
      this.formData.field_supervisor_name = val.field_supervisor
      this.formData.field_supervisor = val.field_supervisor_id
      this.is_loading = true
      await this.getActivity({ limit: 500, cage_category_id: val.cage_category_id})
      await this.getMasterActivityDetail({ cage_category_id: val.cage_category_id})
      this.is_loading = false
    },
    onRemoveCage() {
      this.showAddActivities = false
    },
    onSelectDocs(val) {
      this.formData.chick_supplier_id = val.id
    },
    dateFormatFilter(date) {
      return dateString(date)
    },
    async detailPrice() {
      if(this.formData.contract_price_id !== '' || this.formData.contract_price_code !== '') {
        await this.setModalPrice({ data: this.contractPriceByChickin, date: this.contractPriceByChickin.contract_price_chicken[0].updated_at })
        window.UIkit.modal('#modal-contract-price').show()
      }
    },
    disabledPrepareDates(date) {
      var disableDate = new Date()
      disableDate.setDate(disableDate.getDate() - 4)

      return date < disableDate

      // This is current code, uncomment this code if testing is complete
      // return date < new Date(Date.now() - 8640000)
    },
    disabledEndPrepareDates(date) {
      return date < new Date(this.formData.start_preparation)
    },
    disabledChickInDates(input) {
      const date = new Date(this.formData.end_preparation)
      return input < new Date(dateAddDays(date,1)) || input > new Date(dateAddDays(date,1))
    },
    disabledDates(date) {
      return date < new Date(this.formData.chick_in) || date > new Date(dateAddDays(this.formData.chick_in, DURATION_ACTIVITY.CHICK_IN))
    },
    // disabledHarvestDates(date) {
    //   return (
    //     this.maintenanceLength ? (
    //       date < new Date(dateAddDays(this.formData.start_maintenance, 1)) || date > new Date(dateAddDays(this.formData.start_maintenance, (this.maintenanceLength)))
    //     ) : (
    //       date < new Date(dateAddDays(this.formData.start_maintenance, 1))
    //     )
    //   )
    // },
    // disabledChickOutDates(date) {
    //   return date < new Date(this.formData.harvest) || date > new Date(dateAddDays(this.formData.harvest, DURATION_ACTIVITY.HARVEST))
    // },
    // disabledSanitationDates(date) {
    //   return date < new Date(this.formData.chick_out) || date > new Date(dateAddDays(this.formData.chick_out, DURATION_ACTIVITY.CHICK_OUT))
    // },
    // disabledEndSanitationDates(date) {
    //   return date < new Date(this.formData.sanitation) || date > new Date(dateAddDays(this.formData.sanitation, DURATION_ACTIVITY.SANITATION))
    // },
    goToBack() {
      window.UIkit.modal('#modal-out-confirm').show()
    },
    showSaveConfirmModal() {
      this.distinctLogDetail()
      this.formData.contract_price_id = this.contractPriceByChickin.id

      const showPopup = this.tempActivities.find(x => x.log_detail_activity.length === 0)
      if (showPopup) { // if data activity don't have detail
        this.setShowAlert({ is_show: true, name: (showPopup.name || '-'), type: 2 })
      }
      this.$validator.validateAll().then((success) => {
        if (success) {
          if (this.$validator.errors.any()) return

          if (this.showAlert.is_show) {
            window.UIkit.modal('#modal-show-alert-create-activity').show()
          } else {
            this.setModalAdd(this.formData)
            window.UIkit.modal('#modal-save-confirm').show()
          }
        }
      })
    }
  }
}
</script>

<style scoped>
.bottom-side {
  border-top: 1px solid #e0ebe6;
  padding-top: 32px !important;
  margin-top: 32px !important;
}
.left-repeat {
  padding-left: 0px;
}
.price-field {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 40px;
  border-radius: 6px;
  border: 1px solid #F0F5F3;
  background-color: #F0F5F3;
}
.label-price {
  cursor: pointer;
  margin: 0 12px;
  font-size: 14px;
  color: #049559;
}
.label-price-muted {
  cursor: pointer;
  margin: 0 12px;
  font-size: 14px;
  color: #777;
}
</style>

<template>
  <div
    class="uk-child-width-expand@s uk-grid-small"
    uk-grid
  >
    <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-3@m uk-width-1-5@l uk-width-1-5@xl">
      <div id="toggle-animation">
        <div class="sidebar uk-card uk-card-default uk-card-body uk-height-1-1">
          <ul
            id="sidebar"
            class="uk-nav-default uk-nav-parent-icon sidebar-menu"
            uk-nav
          >
            <li
              v-for="(items, i) in filterSidebarActivity"
              :key="i"
              :class="[showSidebarClass(items, items.childDisplay), {'active-li': items.name === showAct}, {'list-menu': true}]"
            >
              <a
                href="#"
                class="sidebar-display"
                :class="{'active-child': items.name === showAct}"
                @click="showActionContainer(items, i)"
              >
                <span class="icon-dropdown-pemeliharaan uk-margin-small-right">
                  {{ items.name }}
                </span>
              </a>
              <div
                v-if="screenWidth"
                class="uk-padding-remove"
                uk-dropdown
              >
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav uk-dropdown-nav uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      to="#"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </div>
              <template v-else>
                <ul
                  v-if="items.childDisplay"
                  class="uk-nav-sub sub-sidebar-menu"
                >
                  <li
                    v-for="(child) in items.children"
                    :key="child.name"
                    :class="[showSidebarClass(items, items.childDisplay), {'active-li': child.name === showAct}]"
                  >
                    <a
                      :key="child.name"
                      to="#"
                      :class="{'active-child': child.name === showAct}"
                      @click="showContainerActivity(child)"
                    >
                      {{ child.name }}
                    </a>
                  </li>
                </ul>
              </template>
            </li>
          </ul>
        </div>
      </div>
    </div>

    <div class="uk-width-1-1 uk-width-1-1@s uk-width-2-3@m uk-width-4-5@l uk-width-4-5@xl left-repeat">
      <div
        v-show="showAct === 'PERSIAPAN KANDANG'"
        class="uk-card uk-card-default kandang-card"
      >
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div v-if="row.flag === showAct">
            <div class="uk-container">
              <div class="uk-align-left">
                <h6 class="uk-text-bold">
                  Kegiatan
                </h6>
              </div>
            <!-- <div class="uk-align-right">
              <button class="uk-button uk-button-default-danger uk-margin-left" type="button" @click="removeActivities(i)">
                <img v-lazy="`${images}/icon/trash.svg`" alt="" class="uk-margin-small-right">
                Hapus
              </button>
            </div> -->
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.name"
                      class="uk-input"
                      type="text"
                      name="name"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <div class="uk-form-controls">
                      <input
                        v-model="row.activity_category"
                        class="uk-input"
                        type="text"
                        name="activity_category"
                        disabled
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Sub-Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.sub_activity_category"
                      class="uk-input"
                      type="text"
                      name="sub_activity_category"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Keterangan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <textarea
                      id=""
                      v-model="row.description"
                      name="description"
                      class="uk-textarea"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Tipe Input
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.input_type"
                      class="uk-input"
                      type="text"
                      name="input_type"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Waktu Pengerjaan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.processing_time"
                      class="uk-input"
                      type="text"
                      name="processing_time"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr v-show="formActivities.length > 1">
          </div>
        </div>
      <!-- <h2 class="add-activity-line" @click="addActivities(showAct)">
        <span>
          <img v-lazy="`${images}/icon/plus-activity.svg`" alt="" class="uk-margin-small-left" />
          Tambah Kegiatan
        </span>
      </h2> -->
      </div>
      <div
        v-show="showAct === 'CHICK IN'"
        class="uk-card uk-card-default kandang-card"
      >
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div v-if="row.flag === showAct">
            <div class="uk-container">
              <div class="uk-align-left">
                <h6 class="uk-text-bold">
                  Kegiatan
                </h6>
              </div>
            <!-- <div class="uk-align-right">
              <button class="uk-button uk-button-default-danger uk-margin-left" type="button" @click="removeActivities(i)">
                <img v-lazy="`${images}/icon/trash.svg`" alt="" class="uk-margin-small-right">
                Hapus
              </button>
            </div> -->
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.name"
                      class="uk-input"
                      type="text"
                      name="name"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <div class="uk-form-controls">
                      <input
                        v-model="row.activity_category"
                        class="uk-input"
                        type="text"
                        name="activity_category"
                        disabled
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Sub-Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.sub_activity_category"
                      class="uk-input"
                      type="text"
                      name="sub_activity_category"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Keterangan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <textarea
                      id=""
                      v-model="row.description"
                      name="description"
                      class="uk-textarea"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Tipe Input
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.input_type"
                      class="uk-input"
                      type="text"
                      name="input_type"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Waktu Pengerjaan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.processing_time"
                      class="uk-input"
                      type="text"
                      name="processing_time"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr v-show="formActivities.length > 1">
          </div>
        </div>
      <!-- <h2 class="add-activity-line" @click="addActivities(showAct)">
        <span>
          <img v-lazy="`${images}/icon/plus-activity.svg`" alt="" class="uk-margin-small-left" />
          Tambah Kegiatan
        </span>
      </h2> -->
      </div>
      <div
        v-show="showAct === 'SANITASI'"
        class="uk-card uk-card-default kandang-card"
      >
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div v-if="row.flag === showAct">
            <div class="uk-container">
              <div class="uk-align-left">
                <h6 class="uk-text-bold">
                  Kegiatan
                </h6>
              </div>
            <!-- <div class="uk-align-right">
              <button class="uk-button uk-button-default-danger uk-margin-left" type="button" @click="removeActivities(i)">
                <img v-lazy="`${images}/icon/trash.svg`" alt="" class="uk-margin-small-right">
                Hapus
              </button>
            </div> -->
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.name"
                      class="uk-input"
                      type="text"
                      name="name"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <div class="uk-form-controls">
                      <input
                        v-model="row.activity_category"
                        class="uk-input"
                        type="text"
                        name="activity_category"
                        disabled
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Sub-Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.sub_activity_category"
                      class="uk-input"
                      type="text"
                      name="sub_activity_category"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Keterangan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <textarea
                      id=""
                      v-model="row.description"
                      name="description"
                      class="uk-textarea"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Tipe Input
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.input_type"
                      class="uk-input"
                      type="text"
                      name="input_type"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Waktu Pengerjaan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.processing_time"
                      class="uk-input"
                      type="text"
                      name="processing_time"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr v-show="formActivities.length > 1">
          </div>
        </div>
      <!-- <h2 class="add-activity-line" @click="addActivities(showAct)">
        <span>
          <img v-lazy="`${images}/icon/plus-activity.svg`" alt="" class="uk-margin-small-left" />
          Tambah Kegiatan
        </span>
      </h2> -->
      </div>
      <div
        v-show="showContActitvity"
        class="uk-card uk-card-default kandang-card"
      >
        <div
          v-for="(row, i) in formActivities"
          :key="i"
          uk-margin
        >
          <div v-if="row.flag === showAct">
            <div class="uk-container">
              <div class="uk-align-left">
                <h6 class="uk-text-bold">
                  Kegiatan
                </h6>
              </div>
            <!-- <div class="uk-align-right">
              <button class="uk-button uk-button-default-danger uk-margin-left" type="button" @click="removeActivities(i)">
                <img v-lazy="`${images}/icon/trash.svg`" alt="" class="uk-margin-small-right">
                Hapus
              </button>
            </div> -->
            </div>
            <div
              class="uk-child-width-expand@s uk-grid-small"
              uk-grid
            >
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kegiatan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.name"
                      class="uk-input"
                      type="text"
                      name="name"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <div class="uk-form-controls">
                      <input
                        v-model="row.activity_category"
                        class="uk-input"
                        type="text"
                        name="activity_category"
                        disabled
                      >
                    </div>
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Nama Sub-Kategori
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.sub_activity_category"
                      class="uk-input"
                      type="text"
                      name="sub_activity_category"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Keterangan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <textarea
                      id=""
                      v-model="row.description"
                      name="description"
                      class="uk-textarea"
                      rows="5"
                      disabled
                    />
                  </div>
                </div>
              </div>
              <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-1-2@l uk-width-1-2@xl">
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Tipe Input
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.input_type"
                      class="uk-input"
                      type="text"
                      name="input_type"
                      disabled
                    >
                  </div>
                </div>
                <div
                  class="uk-child-width-expand@s uk-grid-small"
                  uk-grid
                >
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-2-5@l uk-width-2-5@xl uk-margin-auto-vertical">
                    Waktu Pengerjaan
                  </div>
                  <div class="uk-width-1-1 uk-width-1-1@s uk-width-1-1@m uk-width-3-5@l uk-width-3-5@xl">
                    <input
                      v-model="row.processing_time"
                      class="uk-input"
                      type="text"
                      name="processing_time"
                      disabled
                    >
                  </div>
                </div>
              </div>
            </div>
            <br>
            <hr v-show="formActivities.length > 1">
          </div>
        </div>
        <h2
          class="add-activity-line"
          @click="addActivities(showAct)"
        >
          <span>
            <img
              v-lazy="`${images}/icon/plus-activity.svg`"
              alt=""
              class="uk-margin-small-left"
            >
            Tambah Kegiatan
          </span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { PREFIX_IMAGE } from '@/utils/constant'

export default {
  props: {
    // eslint-disable-next-line vue/require-default-prop
    panenDate: Number,
    // eslint-disable-next-line vue/require-default-prop
    chickOutDate: Number
  },
  data() {
    return {
      isLoading: false,
      isShow: true,
      showAct: 'PERSIAPAN KANDANG',
      images: PREFIX_IMAGE,
      hideSubSideBar: false,
      showContainerLog: null,
      formLogPost: [],
      formLogActivities: {
        name: '',
        sequence: '',
        note: '',
        log_detail_activity: []
      },
      formLogDetail: {
        name: '',
        activity_category_id: '',
        sub_activity_category_id: '',
        activity_id: '',
        input_type_id: '',
        description: '',
        work_time: '',
        time_start: '',
        time_end: ''
      },
      formActivities: [],
      activitiesSelect: [{ id: 1, code: 'Cek Suhu'}],
      showContActitvity: '',
      filterSidebarActivity: [],
      tempActivities : [],
      pemeliharaanLength: null,
      screenWidth: false
    }
  },
  computed: {
    ...mapGetters({
      activities: 'activity/activities',
      masterActivityDetails: 'masterActivityDetail/masterActivityDetails'
    })
  },
  watch: {
    async panenDate() {
      this.pemeliharaanLength = this.activities.data.filter(x => x.name.includes('HARI KE')).length
      await this.maintenanceDays(this.panenDate, this.chickOutDate)
    },
    async chickOutDate() {
      this.pemeliharaanLength = this.activities.data.filter(x => x.name.includes('HARI KE')).length
      await this.maintenanceDays(this.panenDate, this.chickOutDate)
    },
    async activities() {
      this.is_loading = true
      this.screenWidth = screen.width <= 768
      this.pemeliharaanLength = this.activities.data.filter(x => x.name.includes('HARI KE')).length
      await this.maintenanceDays(this.panenDate, this.chickOutDate)
      this.is_loading = false
    },
    async masterActivityDetails() {
      this.is_loading = true
      await this.getDataMasterDetailActivity()
      this.is_loading = false
    },
    formActivities() {
      this.setDetailLog()
    },
    tempActivities() {
      this.setDetailLog()
    }
  },
  methods: {
    setDetailLog () {
      this.$emit('dataPemeliharaanLength', this.pemeliharaanLength)
      this.$emit('dataDetailLogTemp', this.tempActivities)
      this.$emit('dataDetailLog', this.formActivities)
    },
    getDataMasterDetailActivity() {
      if(this.masterActivityDetails) {
        this.formActivities = []
        this.masterActivityDetails.forEach(el => {
          const act = {
            activity_category: '',
            activity_category_id: '',
            created_at: '',
            description: '',
            id: el.id,
            input_type: '',
            input_type_id: '',
            name: el.name,
            sub_activity_category: '',
            sub_activity_category_id: '',
            time_end: '',
            time_start: '',
            updated_at: '',
            updated_by: ''
          }
          const pushMaster = {
            flag: el.activity,
            activity: el.activity,
            activity_category: el.activity_category,
            activity_category_id: el.activity_category_id,
            activity_id: act,
            description: el.description,
            id: el.id,
            input_type: el.input_type,
            input_type_id: el.input_type_id,
            name: el.name,
            sub_activity_category: el.sub_activity_category,
            sub_activity_category_id: el.sub_activity_category_id,
            time_start: el.time_start,
            time_end: el.time_end,
            processing_time: `${el.time_start} - ${el.time_end}`
          }
          this.formActivities.push(pushMaster)
        })
      } else {
        this.formActivities = []
      }
    },
    async maintenanceDays(childLength, panenChildLength) {
      this.tempActivities = []
      const dataActivity = this.activities.data ? this.activities.data : []
      let child = {}
      let panenChild = {}
      let menu = {}
      const menuChilds = []
      const menuPanenChilds = []
      const sidebarData = []
      dataActivity.forEach(el => {
        if (el.name.search('HARI KE') == 0) {
          child = {
            name: el.name,
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            children    : []
          }
          menuChilds.push(child)
        } else if (el.name.search('PANEN HARI KE') == 0) {
          panenChild = {
            name: el.name,
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: true,
            childDisplay: true,
            children    : []
          }
          menuPanenChilds.push(panenChild)
        } else {
          menu = {
            name: el.name,
            icon: 'icon: triangle-right',
            role: ['super_admin'],
            displayMaintenance: false,
            childDisplay: false,
            children    : []
          }
          sidebarData.push(menu)
        }
      })
      this.filterSidebarActivity = sidebarData
      let maintenanceMenu = {}
      let harvestMenu = {}
      if (dataActivity.length > 0) {
        maintenanceMenu = {
          name: 'PEMELIHARAAN',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : childLength ? (childLength === 0 ? menuChilds : menuChilds.slice(0, childLength)) : menuChilds
        }
        // TODO: logic => maintenanceMenu diletakan sesuai sequence
        this.filterSidebarActivity.splice(2, 0, maintenanceMenu)
      }
      if (dataActivity.length > 0) {
        harvestMenu = {
          name: 'PANEN',
          icon: 'icon: triangle-right',
          role: ['super_admin'],
          displayMaintenance: true,
          childDisplay: true,
          children    : panenChildLength ? (panenChildLength === 0 ? menuPanenChilds : menuPanenChilds.slice(0, Math.round(panenChildLength))) : menuPanenChilds
        }
        // TODO: logic => maintenanceMenu diletakan sesuai sequence
        this.filterSidebarActivity.splice(3, 0, harvestMenu)
      }

      let countData = 0
      dataActivity.forEach((el) => {
        if (el.name.search(`HARI KE`) == 0) {
          countData++
          if (childLength === 0) {
            const log_pemeliharaan = {
              id: el.id,
              name: el.name,
              sequence: el.sequence,
              note: el.note,
              work_date_start: '', 
              work_date_end: '',
              cage_category_id: el.cage_category_id,
              log_detail_activity: []
            }
            this.tempActivities.push(log_pemeliharaan)
          } else if (childLength !== 0 && countData <= childLength) {
            const log_pemeliharaan = {
              id: el.id,
              name: el.name,
              sequence: el.sequence,
              note: el.note,
              work_date_start: '', 
              work_date_end: '',
              cage_category_id: el.cage_category_id,
              log_detail_activity: []
            }
            this.tempActivities.push(log_pemeliharaan)
          }
        } else {
          const log = {
            id: el.id,
            name: el.name,
            sequence: el.sequence,
            note: el.note,
            work_date_start: '', 
            work_date_end: '',
            cage_category_id: el.cage_category_id,
            log_detail_activity: []
          }
          this.tempActivities.push(log)
        }
      })

    },
    showActionContainer(items, i) {
      if (!items.childDisplay) {
        this.showAct = items.name
        this.showContActitvity = false
        this.showContainerLog = i
      } else {
        this.hideSubSideBar = true
        this.showAct = ''
      }
    },
    showContainerActivity(child) {
      this.showAct = child.name
      this.showContActitvity = true
      this.hideSubSideBar = false
    },
    showSidebarActiveChildClass() {
      return 'active-child'
    },
    addActivities (flag) {
      this.formActivities.push(
        {
          flag: flag,
          activity: '',
          activity_category: '',
          activity_category_id: '',
          activity_id: '',
          description: '',
          id: '',
          input_type: '',
          input_type_id: '',
          name: '',
          sub_activity_category: '',
          sub_activity_category_id: '',
          time_start: '',
          time_end: '',
          processing_time: ''
        }
      )
    },
    removeActivities (index) {
      this.formActivities.splice(index, 1)
    },
    getSidebarClass(path){
      return{
        'active': this.$route.fullPath === this.$route.fullPath+path,
        'inactive': this.$route.fullPath !== this.$route.fullPath+path
      }
    },
    showChildSidebar(path, child) {
      const classChild = path.name === 'PEMELIHARAAN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showHarvestChildSidebar(path, child) {
      const classChild = path.name === 'PANEN' && child ? 'uk-parent uk-open uk-maintenance' : ''
      return classChild
    },
    showSidebarClass (path, child) {
      const active = this.getSidebarClass(path.to)
      const childs = this.showChildSidebar(path, child)
      const harvestChilds = this.showHarvestChildSidebar(path, child)
      const classes = `${active} ${childs} ${harvestChilds}`
      return classes
    }
  }
}
</script>

<style scoped>
.width-large {
  width: 20.5%;
}

.width-small {
  width: 7%;
}

#toggle-animation {
  transition: width 500ms;
}

.card-sidebar {
  height: calc(100vh - 50px);
}

.detail-sidebar {
  padding-right: 10%;
  background-color: #E0EBE6;
  height: 50px;
  line-height: 50px;
}

.detail-sidebar a {
  color: #ffffff;
}

.sidebar {
  background-color: #E0EBE6;
  border-radius: 16px 0px 0px 16px;
  height: auto;
}

li {
  padding: 8px 0;
  margin: 1px 0;
}

.uk-active {
  background-color: #004428;
  border-left: 5px solid #004428;
}

.uk-active .uk-icon {
  background-color: #f1f2f3 ;
  color: #f1f2f3;
}

li.uk-active a {
  color: #cacccb;
}
.uk-nav-header {
  color: #fff;
}
.uk-nav-default li a.just-icon {
  padding: 0;
  text-align: center;
}
.active{
  background-color: #004428;
  color: #004428;
}
.active > a{
  color: #fff !important;
}
.uk-nav-default .uk-nav-sub a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
}
.uk-card-body .uk-nav-default > li > a {
    color: #85AD9C;
    font-size: 16px;
    font-weight: bold;
    padding-left: 20px;
}

.uk-nav-default .uk-nav-sub a {
    margin-left: 30px;
}

.uk-nav-default > li > a:hover, .uk-nav-default > li > a:focus, .uk-nav-default .uk-nav-sub a:hover, .uk-parent a:focus {
  color: #84deb9;
}

#kandang-card-activity {
  height: 522px;
}
.uk-nav-sub > li > a:focus, .uk-nav-sub > li > a:hover {
  color: #84deb9;
}

.uk-card-body .uk-nav-default .uk-nav-sub {
    padding-left: 0px;
}
ul.uk-nav-sub {
    padding: 0px;
    height: auto;
    max-height: 360px;
    overflow: auto;
}
.active-child {
  color:#025231 !important;
}

.icon-dropdown-pemeliharaan {
  z-index: 100; 
  margin-right:0px !important;
}
.sidebar-display {
  display:flex; 
  flex-direction: row; 
  justify-content: space-between; 
  padding-right: 0px !important;
}

.sidebar-menu {
  display: flex;
  min-width: 100%;
  overflow-x: auto;
  padding: 0 15px;
}

.list-menu {
  min-width: 70%;
}

.active-li {
  background-color: #FFFF;
  border-radius: 16px 16px 16px 16px;
}

.uk-maintenance {
  background-color: #f1f3f2;
  border-radius: 16px 16px 16px 16px;
}

.sub-sidebar-menu {
  position: absolute;
  left: 0;
  background-color: #f1f3f2;
  width: 100%;
  border-radius: 10px 10px 10px 10px;
  box-shadow: rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px;
}

@media (min-width: 768px) {
  .list-menu {
    min-width: 45%;
  }
}

@media (min-width: 1024px) {
  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    width: 100%;
    border-radius: 0 0 10px 10px;
  }
}

@media (min-width: 1200px) {
  .uk-card-body .uk-nav-default > li > a {
    padding-right: 14px;
  }

  .uk-card-body .uk-nav-default .uk-nav-sub {
      padding-left: 0px;
  }

  .left-repeat {
    padding-left: 0px;
  }

  .active-li {
    border-radius: 16px 0px 0px 16px;
  }

  .uk-maintenance {
    border-radius: 16px 0px 0px 16px;
  }

  .sidebar-menu {
    display: block;
    overflow-x: hidden;
  }

  .sub-sidebar-menu {
    position: unset;
    border-radius: 0 0 10px 10px;
  }

}
</style>
